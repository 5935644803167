import React from 'react';
import { Section, Container } from '@components/global';
import FaqItem from '@common/FaqItem';
import styled from 'styled-components';

const FAQS = [
  {
    title: 'Who certified you as USDA Organic?',
    content: () => (
      <>
        <a href="https://tilth.org/">Oregon Tilth</a>
        &nbsp;gave us our certification.  They've been running certifications since the early 70's and we give
        high praise to their thoroughness.  Our offical farm name is Sojourn Farms, located in Cave Junction OR, 97523.
      </>
    ),
  },
  {
    title: 'Why is saffron so expensive?',
    content: () => (
      <>
      There are nearly 200 freshly-picked flowers packed into each one gram jar we sell.  Those flowers bloom only once a year and we
      put a lot of love into making sure they are of the highest quality.  You can for sure buy saffron for less overseas
      (most likely Iran even if it claims to be Spanish), but once you've tried our US-grown Organic
      saffron you'll understand the hype.  Besides, it doesn't take much to flavor a dish or tea, so one bottle
      should last a while.
      </>
  ),
  },
  {
    title: 'How do I judge the quality of saffron?',
     content: () => (
       <>
       There are 4 important aspects to good saffron.  Color - Look for a deep crimson red; faded color is usually a
       sign the product has been sitting on the shelf too long.  Smell - A strong floral aroma should meet your nose within
       seconds of opening the container; if you need to sniff deeply or smell plastic, look elsewhere.  Texture - Threads
       should be mostly intact; broken pieces or powder are a sign of rough handling or age.  Purity - Outright fraud is
       uncommon with US-grown saffron, but you'll sometimes find leaf fragments or pollen in the jar; this is a sign of
       poor quality control.  These rules of thumb are codified in&nbsp;
       <a href="https://www.iso.org/standard/44523.html">ISO 3632</a>.
       </>
   ),
   },
];

const FaqWrapper = styled.header`
    h1 {
      margin-bottom: 5px;
      color: ${props => props.theme.color.dark_primary};
    }
`;

const Faq = () => (
  <Section id="faq">
    <Container>
      <FaqWrapper>
        <h1>Frequently Asked Questions</h1>
        <div>
          {FAQS.map(({ title, content }) => (
            <FaqItem title={title} key={title}>
              {content()}
            </FaqItem>
          ))}
        </div>
      </FaqWrapper>
    </Container>
  </Section>
);

export default Faq;

