import React from 'react';
import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Faq from '@sections/Faq';
import Header from '@sections/Header';
import Footer from '@sections/Footer';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Container, Section } from '@components/global';
import Img from 'gatsby-image';
import styled from 'styled-components';
import InternalLink from '@common/InternalLink';

const propTypes = {
  data: PropTypes.object.isRequired,
}

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Navbar/>
        <Header/>
        <Section id="about">
          <Container>{this.props.data.aboutEdges.edges.map(edge => (
            <Grid inverse>
              <div>
                <InternalLink href={edge.node.slug}><h2>{edge.node.title}</h2></InternalLink>
                <p>{documentToReactComponents(edge.node.preview.json)}</p>
              </div>
              <Art>
                <InternalLink href={edge.node.slug}><Img fluid={edge.node.imagePortrait.fluid} /></InternalLink>
              </Art>
            </Grid>))}
          </Container>
        </Section>
        <Faq/>
        <Section id="contact">
          <Container>{this.props.data.buyEdges.edges.map(edge => (
            <Grid inverse>
              <Art>
                <InternalLink href={edge.node.slug}><Img fluid={edge.node.imagePortrait.fluid} /></InternalLink>
              </Art>
              <div>
                <InternalLink href={edge.node.slug}><h2>{edge.node.title}</h2></InternalLink>
                <p>{documentToReactComponents(edge.node.preview.json)}</p>
              </div>
            </Grid>))}
          </Container>
        </Section>
        <Footer/>
      </Layout>
    );
  }
}

IndexPage.propTypes = propTypes

export default IndexPage

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 10px;
  }

  p {
    color: ${props => props.theme.color.grey};
  }

  a {
    color: ${props => props.theme.color.dark_primary};
    text-decoration: none
  }

  &:last-child {
    margin-bottom: -1px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin-top: 0px;
  max-width: 500px;
  width: 100%;
`;


export const pageQuery = graphql`
  query {
      aboutEdges: allContentfulAbout(limit: 10, sort:{fields:order}) {
      edges {
        node {
          id
          title
          preview {
            json
          }
          slug
          order
          imagePortrait {
            fluid(maxWidth: 500) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    },
    buyEdges: allContentfulBuy(limit: 1, sort:{fields:order}) {
          edges {
            node {
              id
              title
              preview {
                json
              }
              order
              imagePortrait {
                fluid(maxWidth: 500) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
              }
            }
          }
        }


  }
`;
