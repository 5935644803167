import React from 'react';

import styled from 'styled-components';
import { Section } from '@components/global';

const Header = () => (
  <HeaderWrapper>
    <Section>
      <center>from our farm to your table</center>
    </Section>
  </HeaderWrapper>
);

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.primary};
  background-image: url("/img/crossword.png");

    center {
      font: 60px 'Trattatello', Helvetica, sans-serif;
      color: ${props => props.theme.color.dark_primary};
      margin-top: 10%;
      margin-bottom: 0%;
    }
`;
export default Header;
