import React from 'react';
import styled from 'styled-components';

export const LinkWrapper = styled.div`
  a {
    color: ${props => props.theme.color.dark_primary};
    text-decoration: none;
  }
`;

const InternalLink = ({ href, children, ...other }) => (
  <LinkWrapper>
  <a href={href} {...other}>
    {children}
  </a>
  </LinkWrapper>
);

export default InternalLink;
